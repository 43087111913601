<template>
    <div class="site-pin">
        <router-link to="/">
            Micrographia
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'sitePin'
    }
</script>

