<template>
    <button>
        <img src="@/assets/imgs/close.svg" alt="close">
    </button>
</template>

<script>
    export default {
        name: 'closeBtn'
    }
</script>

<style scoped>
    button {
        padding: 8px;
        background: none;
        border: none;
    }
</style>

