<template>
    <toastBar v-if="toastIsActive"/>
    <sideBar :class="{closed: !sideBarIsActive}" />
    <heroSection :heroSectionText="heroSectionText"/>
    <mgBadge v-if="!contactModalIsActive"/>
    <pitchSection />
    <projectSection />
    <workflowSection />
    <testimonialSection />
    <billBoardSection :displayText="billBoardText"/>
    <footerSection />
</template>

<script>
  // @ is an alias to /src
  import toastBar from '@/components/MG/toast.vue'
  import sideBar from '@/components/MG/sideBar.vue'
  import heroSection from '@/components/MG/heroSection.vue'
  import mgBadge from '@/components/MG_home/badge.vue'
  import pitchSection from '@/components/MG_home/pitchSection.vue'
  import projectSection from '@/components/MG/projectSection.vue'
  import workflowSection from '@/components/MG_home/workflowSection.vue'
  import testimonialSection from '@/components/MG_home/testimonialSection.vue'
  import billBoardSection from '@/components/MG/billBoardSection.vue'
  import footerSection from '@/components/MG/footerSection.vue'

  export default {
    name: 'MG_home',

    components: {
      toastBar,
      sideBar,
      heroSection,
      mgBadge,  
      pitchSection,
      projectSection,
      workflowSection,
      testimonialSection,
      billBoardSection,
      footerSection
    },

    data() {
      return {
        billBoardText: 'We bring your ideas into <br /> a happy reality',
        
         heroSectionText: {
            main: 'We don’t just ',
            special: ['design', 'websites'],
            descr: "We capture your brand's essence through creative designs, delivering carefully sorted expertise <br/> across a wide range of product development.",
            rest: 'and apps',
            actionBtnText: 'See our projects'
          }
      }
    },

    computed: {
        contactModalIsActive() {
            return this.$store.state.contactFormIsActive 
        },

        sideBarIsActive() {
          return this.$store.state.sideBarIsActive
        },

        toastIsActive() {
          return this.$store.state.toast.isActive
        }
      }
  }
</script>
