<template>
    <button>
        <span>
           {{buttonText}} 
        </span>
    </button>
</template>

<script>
    export default {
        name: 'mainActionBtn',

        props: {
            buttonText: {
                type: String,
                default: ""
            },
        },

    }
</script>

