<template>
    <div class="mg-badge regular">

        <div>
            <div>
                <span id="M">M</span>
                <span id="I1">I</span>
                <span id="C">C</span>
                <span id="R1">R</span>
                <span id="O1">O</span>
                <span id="G">G</span>
                <span id="R2">R</span>
                <span id="A1">A</span>
                <span id="P">P</span>
                <span id="H">H</span>
                <span id="I2">I</span>
                <span id="A2">A</span>
                <span id="space"> </span>
                <span id="S1">S</span>
                <span id="O2">O</span>
                <span id="L">L</span>
                <span id="U">U</span>
                <span id="T">T</span>
                <span id="I3">I</span>
                <span id="O3">O</span>
                <span id="N">N</span>
                <span id="S2">S</span>
            </div>
        </div>

        <div class="img-box">
            <img src="@/assets/imgs/arrow-down.png" alt="micrographia-badge">
        </div>

    </div>
</template>

<script>
    export default {
        name: 'mgBadge',

        data() {
            return {
                stampText: 'MICROGRAPHIA SOLUTIONS'
                
            }
        },
    }
</script>

<style lang="scss" scoped>
//    .img-box {
//     display: none;
//    }
</style>

