<template>
    <form 
        class="contact-form"
        @submit.prevent="sendMessage"
     >
            <div class="field">
                <input 
                    type="text" 
                    name="fullName" 
                    id="fullName" 
                    placeholder="Name and Surname"
                    required
                    v-model="contactInfo.fullName"
                />
            </div>

            <div class="field">
                <input 
                    type="email" 
                    name="email" 
                    id="email" 
                    placeholder="Email"
                    required
                    v-model.trim="contactInfo.email"
                />
            </div>

            <div class="field">
                <input 
                    type="text" 
                    name="company" 
                    id="company" 
                    placeholder="Company"
                    required
                    v-model="contactInfo.company"
                />
            </div>

            <div class="field">
                <textarea 
                rows="1"
                    name="message" 
                    id="message" 
                    placeholder="Message"
                    required
                    v-model="contactInfo.message"
                />
            </div>

            <div class="field">
                <input 
                    type="tel" 
                    name="tel" 
                    id="tel" 
                    placeholder="Phone Number"
                    required
                    v-model.number="contactInfo.tel"
                />
            </div>

            <div class="field">
                <input 
                    type="submit" 
                    value="Contact us"
                    class="action-btn regular_action-btn"
                    id="submit-btn"
                    ref="submitBtn"
                />
            </div>
    </form>
</template>

<script>
const querystring = require("querystring");
const axios = require('axios').default;

    export default {
        name: 'contactForm',

        data() {
            return {
                contactInfo: {
                    fullName: null,
                    email: null,
                    company: null,
                    tel:null,
                    message: null
                },

            }
        },

        methods: {
            resetBtn() {
                this.$refs.submitBtn.disabled = false;
                this.$refs.submitBtn.value = "Contact us";
            },

            async sendMessage() {
                this.isDisabled = true;
                this.$refs.submitBtn.value = "Sending..";
                this.$refs.submitBtn.disabled = true;

                //call php mail script

                axios
                .post("https://micrographia.design/mailer.php", querystring.stringify(this.contactInfo))
                .then((response) => {
                    console.log(response);
                    this.$store.dispatch('showToast', {
                        toastMssg: 'Your enquiry has been submitted',
                        toastImg: 'greenTick'
                    });

                    this.$refs.submitBtn.disabled = false;
                    this.$refs.submitBtn.value = "Contact us";
                })
                .catch((error) => {
                    console.log(error);
                    this.$store.dispatch('showToast', {
                        toastMssg: 'Your enquiry was NOT submitted',
                        toastImg: 'redMark'
                    });

                    this.$refs.submitBtn.disabled = false;
                    this.$refs.submitBtn.value = "Contact us";
                })
                
            }
        },
    }
</script>
