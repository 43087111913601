<template>
    <section class="pitch-section">
        <div class="margin">                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                             

            <h2 class="heading">
                We create showcase websites, activations, campaigns, <br /> 
                digital experiences, apps, digital installations, <br />
                progressive web apps (PWA)
            </h2>

            <div>
                <div>
                    <img src="@/assets/imgs/image1.webp" alt="micrographia">
                </div>

                <div>
                    <p class="regular">
                        We at micrographia are a goal-oriented company set out with a systematic approach to building 
                        and creating technically proficient websites for you. <br /><br />

                        Micrographia started as a one-man agency in the year 2019, led by Obiajulu Anayo. 
                        At the time, we were set to provide distinctive graphics and design services.
                        Following through with the rapid evolution of the online space and the increased 
                        demand for better product designs and development, we have since expanded and dived
                        deeper to sort out the best possible innovative way to help businesses and brands tell 
                        their stories and at the same time, create products that place you in a bigger picture.
                    </p>

                    <mainActionBtn 
                        :buttonText="actionBtnText"
                        class="action-btn regular_action-btn"
                        @click="this.$router.push('/about')"
                    />   
                </div>
            </div>

            <h2 class="heading">
                Our services are geared towards 
                turning your ideas into success stories.
            </h2>


            <div class="pitch-cards regular">
                <div v-for="dataGroup in this.pitchCardsData" :key="dataGroup.id" class="pitch-card">
                     <div>
                        <h4>
                            {{dataGroup.heading}}
                        </h4>

                        <p>
                            {{dataGroup.descr}}
                        </p>
                     </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    import mainActionBtn from '../MG/buttons/mainActionBtn.vue'

    export default {
        name: 'pitchSection',

        components: {
            mainActionBtn
        },

        data() {
            return {
                actionBtnText: 'See More',

                pitchCardsData: [
                    {
                        id: 1,
                        heading: 'Product Design',
                        descr: "Using more research and consultative methods, we gradually start to bring your ideas to life. During this process, your feedback is particularly valued as we begin to birth your ideas."
                    },

                    {
                        id: 2,
                        heading: 'Product Development',
                        descr: "This process is carefully worked through as we conduct market research and customer analysis specific to your business needs, in order to deliver designs that afford you credibility, flexibility and market speed."
                    },

                    {
                        id: 3,
                        heading: "Brand Identity Design",
                        descr: "At this point, your satisfaction is what we look forward to. We usher your ideas into reality at last."
                    }
                ]
            }
        },
    }
</script>
