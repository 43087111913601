<template>
    <section class="workflow-section">
        <div class="margin">
            <h3 class="heading">
                We prioritize a systematic approach 
                towards creating technically proficient products"
            </h3>

            <div class="workflow-cards">
                <div 
                    v-for="dataGroup in wfCardsData"
                    :key="dataGroup.id"
                    class="workflow-card"
                 >
                    <div>
                        {{dataGroup.id}}
                    </div>

                    <span class="heading">
                        {{dataGroup.descr}}
                    </span>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
    export default {
        name: 'workflowSection',

        data() {
            return {
                wfCardsData: [
                    {
                        id: '01',
                        descr: 'Top Class Designs'
                    },
                    {
                        id: '02',
                        descr: 'Pixel Perfect Implementation'
                    },
                    {
                        id: '03',
                        descr: 'Product Improvements'
                    }

                ]
            }
        },
    }
</script>
