<template>
    <div class="contact-modal">
        <div>
            <h1 class="heading">
                We’re excited to hear more about your product idea
            </h1>
        </div>
        
        <div>
            <contactForm />
        </div>
    </div>
</template>

<script>
    import contactForm from '@/components/MG/forms/contactForm.vue';
    
    export default {
        name: 'contactModal',

        components: {
            contactForm,
        }

    }
</script>

