<template>
    <div 
        class="hero-section_cntBox"
        :class="{flexed: heroSectionText.heroImg}"
     >
        <div>
            <h1 class="heading">
                {{heroSectionText.main}}

                <span>
                    <span id="design">{{heroSectionText.special[0]}}</span>  <br />
                    <span id="websites">{{heroSectionText.special[1]}}</span> {{heroSectionText.rest}}
                </span>
            </h1>

            <p v-html="heroSectionText.descr">
                
            </p>

            <mainActionBtn 
                class="action-btn regular_action-btn"
                :buttonText="heroSectionText.actionBtnText"
                v-if="heroSectionText.actionBtnText"
                @click="this.$router.push('/projects')"
            />
        </div>

        <div v-if="heroSectionText.heroImg">
            <img :src="imgSrc(heroSectionText.heroImg)" alt="mircrographia">
        </div>
    </div>
</template>

<script>
    import mainActionBtn from './buttons/mainActionBtn.vue';
    import getImage from '@/modules/imgRetriever';

    export default {
        name: 'heroContentBox',

        components: {
            mainActionBtn
        },

        data() {
            return {
                actionBtnText: 'See our projects'
            }
        },

        props: {
            heroSectionText: {
                type: Array,
                default: ()=>[]
            },
        },

        methods: {
            imgSrc(img) {
                return getImage(img);
            }
        },

    }
</script>

