<template>
    <div class="slider">
            <div class="slider-wall" ref="sliderWall">
                <div class="slider-slide" ref="sliderSlide">
                    <div 
                    v-for="dataGroup in testimonials"
                    :key="dataGroup.id"
                     >
                        <p class="regular">
                            {{dataGroup.review}}
                        </p>

                        <div class="regular">   
                            <span>
                                <strong>{{dataGroup.client}}</strong>
                            </span>

                            <br />
                            {{dataGroup.title }}
                        </div>  
                    </div>
                </div>
            </div>

            <div class="slider-control">
                <ul>
                    <button class="slider-crumb active-crumb" @click="moveSlider('0')">  
                    </button>
                   
                    <button class="slider-crumb" @click="moveSlider('100%')">
                    </button>

                    <button class="slider-crumb" @click="moveSlider('200%')">
                    </button>
                </ul>
            </div>
    </div>
</template>

<script>
    export default {
        name: 'mainSlider',

        props: {
            testimonials: {
                type: Array,
                default: ()=>[]
            },
        },

        methods: {
            moveSlider(margin) {
                this.$refs.sliderSlide.style.marginLeft = '-' + margin;

                var sliderCrumbs = document.querySelectorAll('.slider-crumb');

                var i;
                for (i = 0; i < sliderCrumbs.length; i++) {
                        sliderCrumbs[i].classList.remove("active-crumb")
                }

                event.target.classList.add("active-crumb")
                
            }
        },
    }
</script>
