<template>
    <nav class="mobile-nav">
         <ul>
            <li >
                <ul>
                    <li>
                        <router-link @click="goToPage('/')" to="">
                            Home
                        </router-link>
                    </li>

                    <li>
                        <router-link @click="goToPage('/about')" to="">
                            About
                        </router-link>
                    </li>

                    <li>
                        <router-link @click="goToPage('/projects')" to="">
                            Projects
                        </router-link>
                    </li>
                </ul>
            </li>

            <li>
                <mainActionBtn  
                    class="action-btn regular_action-btn" 
                    :buttonText="actionButtonText"                    
                    id="contact-btn"
                    @click="initContactModal"
                />
            </li>
        </ul>
    </nav>
</template>

<script>
    import mainActionBtn from '@/components/MG/buttons/mainActionBtn.vue'

    export default {
        name: 'mobileNav',

        components: {
            mainActionBtn
        },

        data() {
            return {
                actionButtonText: 'Contact us'
            }
        },

         computed: {
            contactModalIsActive() {
                return this.$store.state.contactFormIsActive 
            }
        },

         methods: {
            initContactModal() {
                this.$store.commit('toggleSideBar');
                setTimeout(()=> {
                    this.$store.dispatch('focusContactModal');
                }, '100')
            },
            goToPage(page) {
            this.contactModalIsActive?
            this.$store.dispatch('focusContactModal') & this.$store.commit('toggleSideBar') & this.$router.push(page):
            this.$store.commit('toggleSideBar') & this.$router.push(page)
        }
           
        }
    }
</script>
