<template>
    <aside class="sidebar">
        <closeBtn 
            class="sidebar_close-btn"
            @click="closeSideBar"
        />
        <mobileNav />

        
    </aside>
</template>

<script>
    import closeBtn from '@/components/MG/buttons/closeBtn.vue'
    import mobileNav from '@/components/MG/navs/mobileNav.vue'

    export default {
        name: 'sideBar',

        components: {
            closeBtn,
            mobileNav,
        },

        methods: {
            closeSideBar() {
                this.$store.commit("toggleSideBar");
            }
        },
    }
</script>

