<template>
    <section class="hero-section" :class="{'hero-alt': contactModalIsActive}"
    >
        <div class="margin" :class="{altMargin: heroSectionText.heroImgSrc}">
            <headerBar :class="{'header-alt': contactModalIsActive}"/>

            <section class="hero-x-spot">
                <heroContentBox  v-if="!contactModalIsActive" :heroSectionText="heroSectionText"/>
                <contactModal v-if="contactModalIsActive"/>
            </section>
        </div>
    </section>
</template>

<script>
    import headerBar from '@/components/MG/headerBar.vue'
    import heroContentBox from '@/components/MG/heroContentBox.vue'
    import contactModal from '@/components/MG/contactModal.vue'

    export default {
        name: 'heroSection',

        components: {
            headerBar,
            heroContentBox,
            contactModal
        },

        props: {
            heroSectionText: {
                type: Array,
                default: ()=>[]
            },
        },

        computed: {
            contactModalIsActive() {
                return this.$store.state.contactFormIsActive 
            }
        },
    }
</script>

