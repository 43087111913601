<template>
    <div class="project-block">
        <div>
            <img :src=imgSrc(projectData.img) :alt="projectData.imgAlt">
        </div>

        <div>
            
            <div class="mini">
                {{projectData.category}}
            </div>

            <h4>
                {{projectData.heading}}
            </h4>

            <p class="regular">
                {{projectData.descr}}
            </p>

            <a :href="projectData.liveSite" class="regular" target="blank">
                See Live {{projectData.type}}

                <img src="@/assets/imgs/arrow-right.png" alt="go">
            </a>
        </div>
    </div>
</template>

<script>
    import getImage from '@/modules/imgRetriever'

    export default {
        name: 'projectBlock',

        props: {
            projectData: {
                type: Array,
                default: ()=>[]
            },
        },

        methods: {
            imgSrc(img) {
                return getImage(img) 
            }
        }
        
    }
</script>
