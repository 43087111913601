<template>
    <section class="project-section">
        <div class="margin">
            <h2 class="heading">
                As no one business is the same, every one of our <br />
                projects is entitled to a unique approach and what <br />
                we give is a satisfaction that is unique to you.
            </h2>

            <projectBlock  
                v-for="dataGroup in this.projectsBlockData" 
                :key="dataGroup.id"
                :projectData="dataGroup"
            />
        </div>
    </section>
</template>

<script>
    import projectBlock from './projectBlock.vue';  

    export default {
        name: 'projectSection',

        components: {
            projectBlock
        },

        data() {
            return {
                projectsBlockData: [
                    {
                        id: 1,
                        category: 'Product Design & Development',
                        type: 'Product',
                        heading: 'Neyeka Website',
                        descr: 'Neyeka.com is a ready to wear and custom made fashion website created to showcase and forecast prints and designs creatively put together to reflect your essence',
                        img: 'image5',
                        imgAlt: 'micrographia-project',
                        liveSite: 'https://neyeka.com'
                    },

                    {
                        id: 2,
                        category: 'Product Design & Development',
                        type: 'Product',
                        heading: 'SAC Hair',
                        descr: 'Sac-hair.com is dedicated to ensuring a seamless hair journey with customized protective styles that gets you noticed.',
                        img: 'image6',
                        imgAlt: 'micrographia-project',
                        liveSite: 'https://sac-hair.com'
                    },

                    {
                        id: 3,
                        category: 'Product Development',
                        type: 'Website',
                        heading: 'Paul Omotayo Portfolio Website',
                        descr: 'Paul Omotayo is a porolific product designer. We took on the development of his portfolio websiite and he was super pleased with the outcome.',
                        img: 'image7',
                        imgAlt: 'micrographia-project',
                        liveSite: 'https://paulomotayo.com'
                    }
                ]
            }
        },
    }
</script>

