<template>
    <header>
        <sitePin />
        <mainNav class="main-nav"/>
        <hamBurger @click="openSideBar"/>
    </header>
</template>

<script>
    import sitePin from '@/components/MG/sitePin.vue';
    import mainNav from '@/components/MG/navs/mainNav.vue';
    import hamBurger from '@/components/MG/buttons/hamBurger.vue';

    export default {
        name: 'headerBar',

        data() {
            return {
                actionButtonText: 'Contact'
            }
        },

        components: {
            sitePin,
            mainNav,
            hamBurger
        },

        methods: {
            openSideBar() {
                this.$store.commit("toggleSideBar");
            }
        },

    }
</script>

<style scoped>
    header {
        color: #9c4637;
    }
</style>
