<template>
    <div 
        class="toast regular"
        v-if="toast.isActive"
    >
        <img :src="ImgSrc(toast.img)" alt="message-status-icon">
        {{toast.mssg}}
    </div>
</template>

<script>
    import getImage from '@/modules/imgRetrieverSvg'

    export default {
        name: 'toastBar',

        props: {
            toastInfo: {
                type: Object,
                default: () => {}
            },
        },
        
        methods: {
            ImgSrc(img) {
               return getImage(img); 
            }
        },

        computed: {
            toast() {
                return this.$store.state.toast; 
            }
        },
    }
</script>

