<template>
    <nav>
        <ul>
            <li v-show="!contactModalIsActive">
                <ul>
                    <li>
                        <router-link to="/about">
                            About
                        </router-link>
                    </li>

                    <li>
                        <router-link to="/projects">
                            Projects
                        </router-link>
                    </li>
                </ul>
            </li>

            <li>
                <secActionBtn  
                    class="action-btn regular_action-btn" 
                    :buttonText="actionButtonText"                    
                    :class="{clicked: contactModalIsActive}"
                    @click="initContactModal"
                    id="contact-btn"
                />
            </li>
        </ul>
    </nav>
</template>

<script>
    import secActionBtn from '@/components/MG/buttons/secActionBtn.vue'

    export default {
        name: 'mainNav',

        data() {
            return {
                actionButtonText: 'Contact'
            }
        },

        components: {
            secActionBtn
        },

        computed: {
            contactModalIsActive() {
                return this.$store.state.contactFormIsActive;
            }
        },

        methods: {
            initContactModal() {
                this.$store.dispatch('focusContactModal');
            }
        },
 
    }
</script>

