<template>
    <button class="ham-burger">
        <div></div>
        <div></div>
        <div></div>
    </button>
</template>

<script>
    export default {
        name: 'hamBurger'
    }
</script>

<style lang="scss" scoped>

    @import '@/assets/style/_colors';

    button {
        width: 40px;
        height: max-content;
        display: flex;
        flex-direction: column;
        align-items: end;
        border: none;
        background: none;
    }

    div {
        height: 4px;
        background: $Brown;

        &:first-child {
            width: 85%;
        }

        &:nth-child(2) {
            width: 100%;
        }

        &:nth-child(3) {
            width: 70%;
        }

        &:not(:last-child) {
            margin-bottom: 5px;
        }
    }
</style>