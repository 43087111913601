<template>
    <footer>
        <div class="margin">
            <div>
            <h1>
                Micrographia
            </h1>

            <p class="regular">
                Hey Chief, we don’t have a physical address yet. We’re a fully <br/> 
                remote agency!
            </p>

            <ul>
                <li class="regular">
                    T: +234 81 206 86653
                </li>
                <li class="regular">
                    E: info@micrographia.design
                </li>
            </ul>

            <mainActionBtn 
                :buttonText="actionBtnText"
                class="action-btn regular_action-btn"
                @click="this.$router.push('/contact')"
            />
        </div>

        <footerNav />
        </div>

    </footer>
</template>

<script>
    import mainActionBtn from '@/components/MG/buttons/mainActionBtn.vue'
    import footerNav from '@/components/MG/navs/footerNav.vue'

    export default {
        name: 'footerSection',

        data() {
            return {
                actionBtnText: 'Disscuss a project'
            }
        },

        components: {
            mainActionBtn,
            footerNav
        },
    }
</script>

