<template>
    <section class="testm-section">
        <div class="margin">
                <img src="@/assets/imgs/quotes.svg" alt="micrographia">

                <h2 class="heading">
                    What Our client’s Are Saying
                </h2>

                <mainSlider :testimonials="testimonials"/>      
        </div>
    </section>
</template>

<script>
    import mainSlider from '@/components/MG/mainSlider.vue'

    export default {
        name: 'testimonialSection',

        components: {
            mainSlider  
        },

        data() {
            return {
                testimonials: [
                    {
                        client: 'Koya Ayeni',
                        title: 'Software Developer',
                        review: 'Micrograpia is  a top class design and product led agency. Their designs are user centered and very usable.  Their professionalism is on another level and quality of work is second to none.'
                    },
                    {
                        client: 'Koya Ayeni',
                        title: 'Software Developer',
                        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor aliquam velit at tristique eleifend sed adipiscing. Cras cras nibh diam sed a, adipiscing tellus. Dui vitae eget vitae dolor et. Quam at lectus facilisi'
                    },
                    {
                        client: 'Koya Ayeni',
                        title: 'Software Developer',
                        review: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Auctor aliquam velit at tristique eleifend sed adipiscing. Cras cras nibh diam sed a, adipiscing tellus. Dui vitae eget vitae dolor et. Quam at lectus facilisi'
                    }                
                ],

               
            }
        },
    }
</script>

