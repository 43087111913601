<template>
    <button>
        <span v-if="!contactModalIsActive">
           {{buttonText}} 
        </span>

        <img 
            src="@/assets/imgs/x-in-cirlce.svg" 
            alt="close-button"
            v-if="contactModalIsActive"
        />
    </button>
</template>

<script>
    export default {
        name: 'secActionBtn',

        props: {
            buttonText: {
                type: String,
                default: ""
            },
        },

        computed: {
            contactModalIsActive() {
                return this.$store.state.contactFormIsActive 
            }
        },

    }
</script>

