import { createStore } from 'vuex'

 var store = createStore({
  state() {
    return {
      contactFormIsActive: false,
      mobileNavIsActive: false,
      mssgModalIsActive: false,
      sideBarIsActive: false,

      toast: {
        isActive: false,
        mssg: null,
        img: 'greenTick'
      }
    }
  },

  getters: {
  },

  mutations: {
    toggleContactModal(state) {
      state.contactFormIsActive?
      state.contactFormIsActive = false  :
      state.contactFormIsActive = true
    },

    toggleVhLock() {
      var main = document.body;

      main.classList.contains('screen-lock')? 
      main.classList.remove('screen-lock'):
      main.classList.add('screen-lock')
    },

    toggleMssgModal(state) {
      if (state.mssgModalIsActive === false) {
        state.contactFormIsActive = true
      }
      else {
        state.mssgModalIsActive = false
      }
    },

    toggleSideBar(state) {
      if (state.sideBarIsActive === false) {
        state.sideBarIsActive = true
      }
      else {
        state.sideBarIsActive = false
      }
    },
    
    toggleToast(state) {
        if (state.toast.isActive === false) {
            state.toast.isActive = true;
           
            setTimeout(() => {
              state.toast.isActive = false;
              state.toast.mssg = null;
              state.toast.img = null;
            }, 3000);
         }
    },

    setToastInfo(state, details) {
      state.toast.mssg = details.toastMssg
      state.toast.img = details.toastImg
    }
  },

  actions: {
    focusContactModal ({ commit }) {
      commit('toggleContactModal');
      commit('toggleVhLock');
    },
    showToast ({ commit }, details) {
      commit('setToastInfo', details);
      commit('toggleToast');
    }
  },
  
  modules: {
  }
});

export default store