<template>
    <nav class="footer-nav">
        <ul>
            <li v-for="link in navLinks"
                :key='link.id'
             >

                <router-link :to="link.url">
                    <h3 class="heading">
                            {{link.text}}
                    </h3>

                    <img src="@/assets/imgs/arrow-right-black.png" alt="go">  
                </router-link>
            </li>
           
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'footerNav',

        data() {
            return {
                navLinks: [
                    {
                        id: 1,
                        text: 'About',
                        url: '/about',
                    },
                    {
                        id: 2,
                        text: 'Our Projects',
                        url: '/projects'
                    },
                    {
                        id: 3,
                        text: 'Contact Us',
                        url: '/contact'
                    }
                ]
            }
        },
    }
</script>

