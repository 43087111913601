import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/MG_home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/home',
    redirect: {name: 'home'}
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/MG_about.vue')
  },
  {
    path: '/projects',
    name: 'projects',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "projects" */ '../views/MG_projects.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "conact" */ '../views/MG_contact.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    component: Home
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

  scrollBehavior(to, from, savedPosition) {
    console.log(to, from, savedPosition)
    
    return new Promise((resolve) => {
      setTimeout(()=> {
        if (savedPosition) {
          resolve(savedPosition)
        }
        else {
          resolve ({top: 0})
        }
      }, 500)
    })
  }
})

export default router
