<template>
    <section class="billboard-section">
        <h1 class="heading" v-html="displayText">
            
        </h1>
    </section>
</template>

<script>
    export default {
        name: 'billBoard',

        props: {
            displayText: {
                type: String,
                default: ()=> ''
            },
        },
    }
</script>
